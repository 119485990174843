import React, { useState } from "react";
import { Link } from "react-router-dom";

const Button = (props) => {
  const [copied, setCopied] = useState(false);
  if (!props.data) return;

  const copyText = () => {
    navigator.clipboard.writeText(props.data.slug);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  if (
    String(props.data.slug)
      .toLowerCase()
      .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
  ) {
    return (
      <>
        <a {...props} onClick={() => copyText()}>
          <div>{!copied ? props.data.text : "copied"}</div>
        </a>
      </>
    );
  }
  if (props.data?.slug[0] === "/") {
    return (
      <Link to={props.data.slug || "/"} {...props}>
        <div>{props.data.text}</div>
      </Link>
    );
  } else {
    <a href={props.data.slug || "#"} {...props}>
      <div>{props.data.text}</div>
    </a>;
  }
};

export default Button;
