import React from "react";
import { v4 as uuid } from "uuid";

const Staff = ({ data }) => {
  return (
    <div className="section projects" style={{ marginTop: 0 }}>
      <div className="container">
        <div className="people">
          {data.people?.map((el) => {
            return (
              <div key={uuid()} className="person">
                <img src={el?.staff?.data?.attributes?.headshot?.data?.attributes?.url} loading="lazy" alt="" className="headshot" />
                <div className="personContent">
                  <h2 style={{ textAlign: "left" }} className="heading-4">
                    {el?.staff?.data?.attributes?.name}
                  </h2>
                  <div className="text-block-9" style={{ color: "grey", textAlign: "left" }}>
                    {el?.staff?.data?.attributes?.title}
                  </div>
                  <p style={{ color: "black" }}>{el?.staff?.data?.attributes?.bio}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Staff;
