import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import LoadingPage from "../pages/LoadingPage";
import { v4 as uuid } from "uuid";
import { useQuoteQuestions } from "../hooks/useQuoteQuestions";

import FileInput from "./FileInput";

const QuoteQuestions = ({ firstId }) => {
  const { data } = useSelector((state) => state.cms);
  const cmsData = data["quote-questions"];
  const { currentIndex, questions, answers, onBack, onNext, answerQuestion, error, nextIsSubmit } = useQuoteQuestions(cmsData, firstId);

  const renderAction = () => {
    switch (questions[currentIndex]?.type[0]?.__component) {
      case "questions.text":
        return <TextInput questions={questions} answers={answers} currentIndex={currentIndex} answerQuestion={answerQuestion} />;
      case "questions.radio":
        return <RadioInput questions={questions} answers={answers} currentIndex={currentIndex} answerQuestion={answerQuestion} />;
      case "questions.checkbox":
        return <CheckboxInput questions={questions} answers={answers} currentIndex={currentIndex} answerQuestion={answerQuestion} />;
      case "questions.number":
        return <NumberInput questions={questions} answers={answers} currentIndex={currentIndex} answerQuestion={answerQuestion} />;
      case "questions.file":
        return <FileInput questions={questions} answers={answers} currentIndex={currentIndex} answerQuestion={answerQuestion} />;
      case "questions.client-info":
        return <ClientInfo questions={questions} answers={answers} currentIndex={currentIndex} answerQuestion={answerQuestion} />;

      default:
        return <p>Not found</p>;
    }
  };

  // only display the component if our data has been loaded
  if (!cmsData) return <LoadingPage />;

  return (
    <>
      <div>
        <div className="w-form">
          <form id="email-form" name="email-form" data-name="Email Form" method="get">
            <p data-aos="fade-up">{questions[currentIndex]?.question}</p>
            {renderAction()}
          </form>
        </div>
      </div>
      <div className="question-actions">
        {currentIndex !== 0 && (
          <a onClick={() => onBack()} href="#" className="button question-action-btn back shadow w-button">
            Back
          </a>
        )}

        {!nextIsSubmit ? (
          <a onClick={() => onNext()} href="#" className="button question-action-btn next shadow w-button">
            Next
          </a>
        ) : (
          <a onClick={() => onNext()} href="#" className="button question-action-btn submit shadow w-button">
            Submit
          </a>
        )}
      </div>
      <p style={{ color: "red", textAlign: "center" }}>{error}</p>
    </>
  );
};

export default QuoteQuestions;

const TextInput = ({ questions, answers, currentIndex, answerQuestion }) => {
  return (
    <textarea
      data-aos="fade-up"
      value={answers[currentIndex]?.answer || ""}
      onChange={(e) => {
        answerQuestion(e.target.value, questions[currentIndex].question);
      }}
      placeholder=""
      maxLength={5000}
      className="text-input text-area w-input"
    />
  );
};

const RadioInput = ({ questions, answers, currentIndex, answerQuestion }) => {
  return (
    <Fragment>
      {questions[currentIndex].type[0].options.map((el) => {
        return (
          <label key={uuid()} className="radio-button-field w-radio">
            <input
              type="radio"
              className="w-form-formradioinput w-radio-input"
              onChange={(e) => answerQuestion(e.target.value, questions[currentIndex].question)}
              value={el.text}
              name={el.text}
              checked={answers[currentIndex]?.answer === el.text}
            />
            <span className="radio-button-label w-form-label" htmlFor="radio">
              {el.text}
            </span>
          </label>
        );
      })}
    </Fragment>
  );
};

const CheckboxInput = ({ questions, answers, currentIndex, answerQuestion }) => {
  return (
    <Fragment>
      {questions[currentIndex].type[0].options.map((el) => {
        return (
          <Fragment key={uuid()}>
            <label className="w-checkbox radio-button-field">
              <input
                type="checkbox"
                className="w-checkbox-input"
                onChange={(e) => {
                  let tmp = new Set(answers[currentIndex]?.answer);
                  if (e.target.checked) {
                    tmp.add(el.text);
                  } else {
                    tmp.delete(el.text);
                  }
                  answerQuestion(tmp, questions[currentIndex].question);
                }}
                value={el.text}
                name={el.text}
                checked={typeof answers[currentIndex]?.answer === "object" && answers[currentIndex]?.answer.has(el.text)}
              />
              <span className="radio-button-label w-form-label" htmlFor="checkbox">
                {el.text}
              </span>
            </label>
            <div className="option-subtext">{el.subtext}</div>
          </Fragment>
        );
      })}
    </Fragment>
  );
};

const NumberInput = ({ questions, answers, currentIndex, answerQuestion }) => {
  return (
    <input
      data-aos="fade-up"
      type="number"
      className="text-input w-input"
      maxLength={256}
      value={answers[currentIndex]?.answer || 0}
      onChange={(e) => {
        answerQuestion(e.target.value, questions[currentIndex].question);
      }}
    />
  );
};

const ClientInfo = ({ questions, answers, currentIndex, answerQuestion }) => {
  const [value, setValue] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    description: "",
  });

  const handleChange = (val, tag) => {
    let tmp = { ...value };
    tmp[tag] = val;
    setValue(tmp);
    answerQuestion(tmp, questions[currentIndex].question);
  };

  return (
    <Fragment>
      <label htmlFor="name">Name</label>
      <input
        value={value["name"]}
        onChange={(e) => handleChange(e.target.value, "name")}
        type="text"
        className="text-input w-input"
        maxLength={256}
        name="name"
        data-name="Name"
        placeholder=""
        id="name"
      />
      <label htmlFor="email">Email Address</label>
      <input
        value={value["email"]}
        onChange={(e) => handleChange(e.target.value, "email")}
        type="text"
        className="text-input w-input"
        maxLength={256}
        name="email"
        data-name="Email"
        placeholder=""
        id="email"
        required=""
      />
      <label htmlFor="Phone">Phone Number</label>
      <input
        value={value["phone"]}
        onChange={(e) => handleChange(e.target.value, "phone")}
        type="text"
        className="text-input w-input"
        maxLength={256}
        name="Phone"
        data-name="Phone"
        placeholder=""
        id="Phone"
      />
      <label htmlFor="Address">Project Address</label>
      <input
        value={value["address"]}
        onChange={(e) => handleChange(e.target.value, "address")}
        type="text"
        className="text-input w-input"
        maxLength={256}
        name="Address"
        data-name="Address"
        placeholder=""
        id="Address"
      />
      <label htmlFor="Description">Description of Work</label>
      <textarea
        value={value["description"]}
        onChange={(e) => handleChange(e.target.value, "description")}
        data-name="Description"
        placeholder=""
        maxLength={5000}
        className="text-input text-area w-input"
      />
    </Fragment>
  );
};
