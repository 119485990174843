import { AUTHENTICATE, AUTH_ERROR, AUTH_SUCCESS } from "../actions/auth";

const initialState = {
  jwt: null,
  user: null,
  authError: null,
  authSuccess: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE:
      return {
        ...state,
        user: action.user,
        jwt: action.jwt,
        authError: false,
        authSuccess: false,
      };

    case AUTH_ERROR:
      return {
        ...state,
        authError: action.authError,
      };

    case AUTH_SUCCESS:
      return {
        ...state,
        authSuccess: action.authSuccess,
      };

    default:
      return state;
  }
};
