// Our Navbar component
// This will need to be modified to match the JSX coming from webflow

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { useLocation } from "react-router-dom";
import Hamburger from "./Hamburger";
import Button from "./Button";
import { useAuthFunctions } from "../hooks/useAuthFunctions";

function Navbar() {
  const { data } = useSelector((state) => state.cms);

  const { colorChange } = useSelector((state) => state.nav);
  const singleTypeSlug = "navbar";
  const navData = data[singleTypeSlug] && data[singleTypeSlug].attributes;
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { user, handleLogout } = useAuthFunctions();

  const handleClick = () => {
    setOpen(!open);
  };

  // only display the component if our data has been loaded
  if (!navData) return null;

  return (
    <div
      data-collapse="medium"
      data-animation="default"
      data-duration={400}
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      className={`navbar w-nav ${open && "nav-open"} ${colorChange && "now--color shadow"}`}
      style={open ? { background: "#fff" } : {}}
    >
      <div className="navigation-wrap">
        <Link to="/" aria-current="page" className="logo-link w-nav-brand w--current">
          <img src={navData?.logo_small?.data?.attributes?.url} loading="lazy" alt="" className={`navbar-logo   ${!colorChange && "hidden"}`} />
          <img src={navData?.logo?.data?.attributes?.url} loading="lazy" alt="" className={`navbar-logo-small   ${colorChange && "hidden"}`} />
        </Link>

        <div className="menu">
          <nav role="navigation" className="navigation-items w-nav-menu">
            <div className="navspacer" />
            <div className="div-block-27">
              {navData?.links?.map((item) => {
                if (!item.subLinks || item.subLinks.length === 0) {
                  return (
                    <Link key={uuid()} to={item.slug || "/"} className={`navigation-item scrollcolor w-nav-link ${location.pathname === item.slug && "w--current"}`}>
                      {item.text}
                    </Link>
                  );
                } else {
                  return <DropDown user={user} key={uuid()} item={item} handleLogout={handleLogout} colorChange={colorChange} />;
                }
              })}
            </div>

            {navData.button ? (
              <Link to={navData.button.slug} className="button cc-contact-us w-inline-block">
                <div>{navData.button.text}</div>
              </Link>
            ) : (
              <div className="navspacer" />
            )}
          </nav>
          <div className="menu-button w-nav-button " onClick={handleClick} style={{ backgroundColor: "white", borderRadius: 200, padding: 10 }}>
            <Hamburger color={open ? "black" : "black"} />
          </div>
        </div>
      </div>
      {open && (
        <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0" style={{ height: open ? "100vh" : "0vh", display: "block" }}>
          <nav role="navigation" className="navigation-items w-nav-menu" data-nav-menu-open="">
            <div className="navspacer" />
            <div className="div-block-27">
              {navData?.links?.map((item) => {
                if (!item.subLinks || item.subLinks.length === 0) {
                  return (
                    <Link key={uuid()} to={item.slug || "/"} className={`navigation-item scrollcolor w-nav-link w--nav-link-open ${location.pathname === item.slug && "w--current"}`}>
                      {item.text}
                    </Link>
                  );
                } else {
                  return <DropDownMobile user={user} key={uuid()} item={item} handleLogout={handleLogout} colorChange={colorChange} />;
                }
              })}
            </div>
            {navData.button && <Button data={navData.button} className="button cc-contact-us w-inline-block" />}
          </nav>
        </div>
      )}
    </div>
  );
}

export default Navbar;

const DropDown = ({ item, user, handleLogout, colorChange }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="dropdown" onMouseEnter={() => setOpen(true)} onMouseLeave={() => setTimeout(() => setOpen(false), 500)}>
      <div className="dropdown-header">
        <div className="navigation-item scrollcolor">Client</div>
      </div>
      <div className={`dropdown-body ${open && "open"}  ${colorChange && "now--color dropdown-shadow"}`}>
        <RenderDropdownLinks item={item} user={user} handleLogout={handleLogout} />
      </div>
    </div>
  );
};

const DropDownMobile = ({ item, user, handleLogout, colorChange }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="dropdown">
      <div className="dropdown-header" onClick={() => setOpen(!open)}>
        <div className="navigation-item scrollcolor">Client</div>
      </div>
      <div className={`dropdown-body ${open && "open"}  ${colorChange && "now--color dropdown-shadow"}`}>
        <RenderDropdownLinks item={item} user={user} handleLogout={handleLogout} />
      </div>
    </div>
  );
};

const RenderDropdownLinks = ({ item, user, handleLogout }) => {
  return (
    <>
      {item?.subLinks?.map((subitem) => {
        if (subitem.slug === "/profile" && !user) return null;
        if (subitem.slug === "/auth?q=logout" && !user) return null;
        if (subitem.slug === "/auth?q=signup" && user) return null;
        if (subitem.slug === "/auth?q=login" && user) return null;

        if (subitem.slug === "/auth?q=logout" && user)
          return (
            <a key={uuid()} onClick={handleLogout} className="navigation-item scrollcolor w-nav-link">
              {subitem.text}
            </a>
          );
        return (
          <Link key={uuid()} to={subitem.slug} className="navigation-item scrollcolor w-nav-link">
            {subitem.text}
          </Link>
        );
      })}
    </>
  );
};
