import { SET_COLOR_CHANGE } from "../actions/nav";

const initialState = {
  colorChange: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COLOR_CHANGE:
      return { ...state, colorChange: action.colorChange };
    default:
      return state;
  }
};
