import React, { useState } from "react";
import { v4 as uuid } from "uuid";

const ButtonRow = ({ data, handleChange, containerClass, linkClass, linkActiveClass, textAttribute, active }) => {
  const onClick = (i) => {
    handleChange(i);
  };

  return (
    <div className={containerClass}>
      {data?.map((el, i) => {
        return (
          <a key={uuid()} onClick={() => onClick(i)} className={`${linkClass} ${active === i && linkActiveClass}`}>
            {el.attributes?.[textAttribute]}
          </a>
        );
      })}
    </div>
  );
};

export default ButtonRow;
