import axios from "axios";

export const FETCH_INVOICES = "FETCH_INVOICES";
export const CLEAR_INVOICES = "CLEAR_INVOICES";

export const fetchInvoices = (jwt) => {
  console.log("dispatching: fetchInvoices");
  return async (dispatch) => {
    try {
      // make request
      var config = {
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/quickbooks/invoices`,
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      };
      const response = await axios(config);

      //   dispatch action
      dispatch({
        type: FETCH_INVOICES,
        data: response.data,
      });
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  };
};

export const clearInvoices = () => {
  console.log("dispatching: clearInvoices");
  return async (dispatch) => {
    dispatch({
      type: CLEAR_INVOICES,
    });
  };
};
