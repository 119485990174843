import React from "react";

const Hamburger = ({ color }) => {
  return (
    <svg
      className="menu-icon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={22}
      zoomAndPan="magnify"
      viewBox="0 0 810 809.999993"
      height={22}
      preserveAspectRatio="xMidYMid meet"
      version={1.0}
    >
      <defs>
        <clipPath id="08e87c453c">
          <path d="M 0 597.160156 L 810 597.160156 L 810 698.386719 L 0 698.386719 Z M 0 597.160156 " clipRule="nonzero" />
        </clipPath>
      </defs>
      <path
        fill={color}
        d="M 759.398438 212.582031 L 172.117188 212.582031 C 144.171875 212.582031 121.492188 189.925781 121.492188 161.980469 C 121.492188 134.035156 144.171875 111.378906 172.117188 111.378906 L 759.398438 111.378906 C 787.34375 111.378906 810 134.035156 810 161.980469 C 810 189.925781 787.34375 212.582031 759.398438 212.582031 "
        fillOpacity={1}
        fillRule="nonzero"
      />
      <g clipPath="url(#08e87c453c)">
        <path
          fill={color}
          d="M 759.398438 698.386719 L 50.601562 698.386719 C 22.660156 698.386719 0 675.730469 0 647.777344 C 0 619.832031 22.660156 597.171875 50.601562 597.171875 L 759.398438 597.171875 C 787.34375 597.171875 810 619.832031 810 647.777344 C 810 675.730469 787.34375 698.386719 759.398438 698.386719 "
          fillOpacity={1}
          fillRule="nonzero"
        />
      </g>
      <path
        fill={color}
        d="M 759.398438 455.480469 L 361.128906 455.480469 C 333.164062 455.480469 310.484375 432.824219 310.484375 404.878906 C 310.484375 376.933594 333.164062 354.273438 361.128906 354.273438 L 759.398438 354.273438 C 787.34375 354.273438 810 376.933594 810 404.878906 C 810 432.824219 787.34375 455.480469 759.398438 455.480469 "
        fillOpacity={1}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default Hamburger;
