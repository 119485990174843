import React, { useState } from "react";
import { useSelector } from "react-redux";
import RichText from "../components/RichText";
import ButtonRow from "../components/ButtonRow";
import { useLocation, useNavigate } from "react-router-dom";

const Services = ({ data }) => {
  const navigate = useNavigate();
  const { data: cmsData } = useSelector((state) => state.cms);
  const { services } = cmsData;
  // const [active, setActive] = useState(0);

  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const q = query.get("q");

  let active = 0;
  services?.map((el, i) => {
    if (el.attributes.title === q) active = i;
  });

  const handleClick = (val) => navigate(`/services?q=${services[val]?.attributes?.title}`);

  return (
    <div className="section suppliers wf-section">
      <div className="container-3 w-container">
        <ButtonRow
          active={active}
          data={services}
          handleChange={(a) => handleClick(a)}
          containerClass="div-block-25"
          linkClass="button service-button w-button"
          linkActiveClass="purple"
          textAttribute="title"
        />
        <ServicesRow item={services[active]?.attributes} />
      </div>
    </div>
  );
};

export default Services;

const ServicesRow = ({ item }) => {
  const [index, setIndex] = useState(0);

  const stepIndex = (step) => {
    let tmp = index + step;
    if (tmp >= item?.slider_photos?.data.length) tmp = 0;
    if (tmp < 0) tmp = item?.slider_photos?.data.length - 1;
    setIndex(tmp);
  };
  return (
    <div className="service-row">
      {item?.slider_photos?.data?.length > 0 && (
        <div className="service-imgs">
          <img style={{ height: "50vh" }} src={item?.slider_photos?.data?.[index]?.attributes?.url} loading="lazy" alt="" className="image-8 shadow" />
          <div className="slide-arrow">
            <img onClick={() => stepIndex(-1)} src="images/Untitled-design.svg" loading="lazy" alt="" className="slide-arrow-icon left shadow" />
          </div>
          <div className="slide-arrow right">
            <img onClick={() => stepIndex(1)} src="images/Untitled-design.svg" loading="lazy" alt="" className="slide-arrow-icon shadow" />
          </div>
        </div>
      )}
      <div className="service-desc">
        <h2 className="heading-5">{item.title}</h2>
        <RichText text={item.description} left />
      </div>
    </div>
  );
};
