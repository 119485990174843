// Our Footer component
// Thsi will need to be modified to match the JSX coming from webflow

import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="page-spacer"></div>
      <div className="section footer shadow">
        <div className="container footer">
          <div className="footer-wrap">
            <div className="text-block">
              Copyright © {new Date().getFullYear()}
              <Link to="/" className="link">
                Mark of Excellence Landscaping Inc.
              </Link>
            </div>
            <div className="text-block">
              Designed by
              <a href="https://www.bellwoodsweb.com" target="_blank" className="link">
                Bellwoods Web Development Inc.
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
