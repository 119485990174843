// pull all named data from our form
// used first in the signup form
export const pullFormData = (e) => {
  let data = {};
  for (let i = 0; i < e.target.elements.length; i++) {
    let name = e.target.elements[i].name;

    if (name) {
      data[name] = e.target.elements[i].value;
    }
  }
  return data;
};
