import React from "react";
import QuoteQuestions from "../components/QuoteQuestions";

const QuoteForm = ({ data }) => {
  return (
    <div className="section projects">
      <div className="container quote-form">
        <QuoteQuestions firstId={data?.first_question.data.id} />
      </div>
    </div>
  );
};

export default QuoteForm;
