import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const useQuoteQuestions = (cmsData, firstId) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [error, setError] = useState();
  const [nextIsSubmit, setNextIsSubmit] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!cmsData) return;
    let tmp = [cmsData.filter((el) => el.id === firstId)[0]?.attributes];
    setQuestions(tmp);
  }, [cmsData]);

  const answerQuestion = (ans, question, type) => {
    let tmp = [...answers];
    if (tmp.length < currentIndex) {
      tmp.push({ question: question, answer: ans, type: type });
    } else {
      tmp[currentIndex] = { question: question, answer: ans, type: type };
    }
    setAnswers(tmp);
    setError();
  };

  const onBack = () => {
    setCurrentIndex(currentIndex - 1);
    setNextIsSubmit(false);
  };

  useEffect(() => {
    if (!questions[currentIndex]) return;
    if ((!questions[currentIndex]?.type[0]?.next_questions || !questions[currentIndex]?.type[0]?.next_questions.length === 0) && !questions[currentIndex]?.type[0]?.next_question.data) {
      setNextIsSubmit(true);
      return;
    }
  }, [currentIndex]);

  const onNext = () => {
    try {
      // check if we've answered question - if not push null
      let tmpAnswers = [...answers];
      if (!tmpAnswers[currentIndex]) {
        tmpAnswers[currentIndex] = { question: questions[currentIndex].question, answer: null };
        setAnswers(tmpAnswers);
      }
      //check if were done
      if (nextIsSubmit) {
        submit(tmpAnswers);
        return;
      }

      // were not done

      let nextId;
      //conditional next question
      if (questions[currentIndex]?.type?.[0]?.next_question) {
        nextId = questions[currentIndex]?.type?.[0]?.next_question?.data?.id;
      } else if (questions[currentIndex]?.type?.[0]?.next_questions) {
        if (questions[currentIndex]?.type[0]?.next_questions.data.length <= 1) {
          nextId = questions[currentIndex]?.type?.[0]?.next_questions?.data[0]?.id;
        } else {
          let questionIndex;
          questions[currentIndex].type[0].options.map((el, i) => {
            if (el.text === tmpAnswers[currentIndex].answer || (typeof answers[currentIndex].answer === "object" && answers[currentIndex].answer.has(el.text))) questionIndex = i;
          });
          nextId = questions[currentIndex].type[0].next_questions.data[questionIndex].id;
        }
      }

      let nextQ = cmsData.filter((el) => el.id === nextId)[0]?.attributes;
      let tmp = [...questions];
      if (tmp.length < currentIndex + 1) {
        tmp.push(nextQ);
      } else {
        tmp[currentIndex + 1] = nextQ;
      }

      setQuestions(tmp);
      setCurrentIndex(currentIndex + 1);
    } catch (error) {
      console.log(error);
      setError("Must answer this question");
    }
  };

  const submit = (answers) => {
    console.log("SUBMIT:\n\n", answers);
    let tmpAnswers = convertAnswers(answers);

    const data = {};
    const formData = new FormData();

    // loop through inputs
    for (let i = 0; i < tmpAnswers.length; i++) {
      // if regular push to form data
      if (tmpAnswers[i].type !== "file") {
        data[`Q${i + 1}`] = tmpAnswers[i];
      } else if (tmpAnswers[i].type === "file") {
        //if file push to files
        for (let j = 0; j < tmpAnswers[i].answer.length; j++) {
          formData.append(`files.files`, tmpAnswers[i].answer[j], tmpAnswers[i].answer[j].name);
        }
      }
    }

    formData.append("data", JSON.stringify(data));

    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/ez-upload`, formData)
      .then(() => {
        navigate(window.location.pathname + "?status=success");
        console.log("success");
      })
      .catch((error) => {
        console.log("error");
        navigate(window.location.pathname + "?status=error");
      });
  };

  return {
    currentIndex,
    questions,
    answers,
    onBack,
    onNext,
    answerQuestion,
    error,
    nextIsSubmit,
  };
};

const convertAnswers = (answers) => {
  // let arr = Array.from(s);
  let tmpAnswers = [];
  answers.map((el) => {
    let tmp = { ...el };
    if (tmp.answer?.has) {
      tmp.answer = Array.from(tmp.answer);
    }
    tmpAnswers.push(tmp);
  });

  return tmpAnswers;
};
