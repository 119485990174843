import React, { useEffect, useState } from "react";
import { useAuthFunctions } from "../hooks/useAuthFunctions";
import { v4 as uuid } from "uuid";
import ProjectPhotoSlider from "./ProjectPhotoSlider";
import RichText from "./RichText";
const ProjectsTab = ({ active }) => {
  const { user, findMe } = useAuthFunctions();
  const projects = user?.projects;
  const [index, setIndex] = useState(0);
  const [currentImages, setCurrentImages] = useState([]);

  useEffect(() => {
    findMe();
  }, []);

  useEffect(() => {
    if (projects) {
      let tmp = projects[index]?.photos?.map((el) => el?.image?.url);
      setCurrentImages(tmp);
    }
  }, [index]);

  if (!projects || projects.length === 0)
    return (
      <div data-w-tab="Tab 2" className="tab-pane w-tab-pane">
        <h2 className="subtleheading left">No Projects</h2>
      </div>
    );
  return (
    <div data-w-tab="Tab 2" className={`tab-pane w-tab-pane ${active && "w--tab-active"}`}>
      <div className="div-block-26">
        {projects?.map((el, i) => {
          return (
            <a key={uuid()} onClick={() => setIndex(i)} href="#" className={`button project-btn w-button ${i === index && "purple"}`}>
              {el.title}
            </a>
          );
        })}
      </div>
      {/* <h2 className="subtleheading left">Photos</h2> */}
      <ProjectPhotoSlider data={currentImages} />

      {projects[index]?.notes && (
        <>
          <h3>Notes</h3>
          <div className="rich-text-block w-richtext">
            <RichText text={projects[index].notes} left />
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectsTab;
