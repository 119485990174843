import "../css/ResetPassword.css";
import React from "react";

import { useAuthFunctions } from "../hooks/useAuthFunctions";

function ResetPasswordPage() {
  const { handleResetPassword, password, setPassword, passwordConfirm, setPasswordConfirm, loading } = useAuthFunctions();

  return (
    <div className="reset">
      <form onSubmit={handleResetPassword}>
        <h2>Reset Password</h2>
        <p>It happens to the best of us!</p>

        <label>Password</label>
        <input type="text" placeholder="" value={password} onChange={(e) => setPassword(e.target.value)} autoComplete="new-password" required />
        <label>Confirm Password</label>
        <input type="text" placeholder="" value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} autoComplete="off" required />

        <input type="submit" value="Submit" />
        {loading && <p style={{ textAlign: "center" }}>This can take a couple minutes!</p>}
      </form>
      <div className="authForm row centered signupAction"></div>
    </div>
  );
}

export default ResetPasswordPage;
