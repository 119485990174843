import React from "react";

const LoadingPage = () => {
  return (
    <div className="utility-page-wrap">
      <div className="_404-wrap">
        <div className="_404-content-wrap">
          <img src="images/Logo-icon.png" loading="lazy" alt="" className="image-9" />

          <div className="paragraph-bigger cc-bigger-light">Loading...</div>
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
