import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import Button from "../components/Button";
import ImageFader from "../components/ImageFader";

const Quotes = ({ data }) => {
  const [active, setActive] = useState(0);

  const changeSlide = (i) => {
    let tmp = i;
    if (tmp > data.projects.length - 1) tmp = 0;
    setActive(tmp);
  };

  return (
    <div className="section project-slider wf-section">
      <h2 data-aos="fade-up" className="subtleheading left">
        {data.text}
      </h2>
      <div className="quote-slider">
        {data?.projects?.map((el, i) => (
          <Card key={uuid()} item={el} index={i} active={active} setIndex={changeSlide} all={data.projects} />
        ))}
      </div>
    </div>
  );
};

export default Quotes;

const Card = ({ item, index, active, setIndex, all }) => {
  return (
    <div className={`w-layout-grid grid quote-card ${index == active && "show"}`}>
      <div id="w-node-_116fced4-9d7d-ae45-8a32-a25f70f6c61c-a2ca4a83" className="div-block-2 shadow">
        <p data-aos="fade-up" className="paragraph-5">
          {item.quote}
        </p>
        <div className="text-block-11">{item.customer}</div>
        {item?.project && <Button data={{ text: "View Project →", slug: `/projects?project_id=${item?.project?.data?.id}` }} className="button quote green w-button" />}

        <div className="slider-pagination-container">
          {all?.map((el, i) => {
            return <div style={{ cursor: "pointer" }} key={uuid()} onClick={() => setIndex(i)} className={`slider-icon ${i === index && "slider-icon-current"}`} />;
          })}
        </div>
        <div className="slider-arrow" onClick={() => setIndex(index + 1)}>
          <div>→</div>
        </div>
      </div>
      <div id="w-node-b6c4c7de-b63b-b2b6-d89f-69439f8173f2-a2ca4a83" className="image-grow-container shadow">
        <ImageFader images={item?.project?.data?.attributes?.photos} loading="lazy" alt="" className="image" />
      </div>
    </div>
  );
};
