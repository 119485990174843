import React, { useState } from "react";
import Button from "../components/Button";
import { v4 as uuid } from "uuid";

const FAQs = ({ data }) => {
  return (
    <div className="section suppliers wf-section" style={{ marginTop: 0 }}>
      <div className="w-container">
        <div className="faqs">
          {data?.faqs?.map((el, i) => (
            <FAQ key={uuid()} item={el} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQs;

const FAQ = ({ item }) => {
  const [active, setActive] = useState();
  return (
    <div data-aos="fade-up" className="faq">
      <div className="faq-header" onClick={() => setActive(!active)}>
        <div className="faq-question">{item.question}</div>
        <img src="images/chevron.png" loading="lazy" alt="" className={`faq-arrow ${active && "open"}`} />
      </div>
      <div className={`faq-body ${active && "open"}`}>
        <p>{item.answer}</p>
        <Button data={item.button} className="button purple centered w-button" />
      </div>
    </div>
  );
};
