import React from "react";
import Button from "../components/Button";
import Waypoint from "../components/Waypoint";

const Hero = ({ data }) => {
  return (
    <>
      <div data-w-id="86e64837-0616-515b-4568-76c147234d1d" className="section cc-store-home-wrap shadow" style={{ marginBottom: 100 }}>
        <div
          className="intro-header"
          style={{ backgroundImage: `radial-gradient(circle farthest-corner at 50% 50%, rgba(0, 0, 0, 0.72), rgba(0, 0, 0, 0.08)), url(${data?.background?.data?.attributes?.url})` }}
        >
          <div className="intro-content cc-homepage">
            <img src={data?.logo?.data?.attributes?.url || "images/Logo-noBG.png"} loading="lazy" alt="" className="mainlogo" />
            <h1 className="heading-jumbo text-shadow hidden">Mark of Excellence Landscaping</h1>
            <div data-w-id="86e64837-0616-515b-4568-76c147234d24" className="paragraph-bigger cc-bigger-white-light text-shadow">
              {data.subtitle}
              <br />
            </div>
            <Button data={data.button} data-w-id="86e64837-0616-515b-4568-76c147234d27" className="button cc-jumbo-button cc-jumbo-white animated w-inline-block" />
          </div>
        </div>
      </div>
      <Waypoint />
    </>
  );
};

export default Hero;
