import React, { Fragment, useEffect, useRef, useState } from "react";
import { FileDrop } from "react-file-drop";
import { v4 as uuid } from "uuid";

const FileInput = ({ questions, answers, currentIndex, answerQuestion }) => {
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);

  const styles = { border: "1px solid black", width: 600, color: "black", padding: 20 };
  useEffect(() => {
    if (files) answerQuestion(files, questions[currentIndex].question, "file");
  }, [files]);

  const onTargetClick = () => {
    fileInputRef.current.click();
  };

  const calcSize = (file) => {
    var _size = file.size;
    var fSExt = new Array("Bytes", "KB", "MB", "GB"),
      i = 0;
    while (_size > 900) {
      _size /= 1024;
      i++;
    }
    var exactSize = Math.round(_size * 100) / 100 + " " + fSExt[i];
    return exactSize;
  };

  const removeFileFromFileList = (index) => {
    const dt = new DataTransfer();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (index !== i) dt.items.add(file); // here you exclude the file. thus removing it.
    }

    setFiles(dt.files); // Assign the updates list
  };

  return (
    <>
      <FileDrop onTargetClick={onTargetClick} onDrop={(files, event) => setFiles(files)}>
        <div className="file-target">
          <img
            src="images/File-Upload.png"
            loading="lazy"
            sizes="100.00000762939453px"
            srcSet="images/File-Upload-p-500.png 500w, images/File-Upload-p-800.png 800w, images/File-Upload.png 1080w"
            alt=""
            className="image-5"
          />
          <div>
            <div className="text-block-6">
              Drag and drop or{" "}
              <a href="#" className="link-2">
                browse
              </a>{" "}
              your files
            </div>
          </div>
        </div>
      </FileDrop>

      <input id="file-upload" type="file" onChange={(e) => setFiles(e.target.files)} multiple ref={fileInputRef} />
      {Array.from(files).map((file, i) => (
        <div className="div-block-21" key={uuid()}>
          <img
            src="images/File-Upload-1.png"
            loading="lazy"
            sizes="(max-width: 479px) 33vw, 75px"
            srcSet="images/File-Upload-1-p-500.png 500w, images/File-Upload-1-p-800.png 800w, images/File-Upload-1.png 1080w"
            alt=""
            className="image-6"
          />
          <div>
            <div>{file.name}</div>
            <div className="text-block-7">{calcSize(file)}</div>
          </div>
          <div className="div-block-22" onClick={() => removeFileFromFileList(i)}>
            <img
              src="images/File-Upload-2.png"
              loading="lazy"
              sizes="(max-width: 1279px) 40px, (max-width: 1439px) 3vw, 40px"
              srcSet="images/File-Upload-2-p-500.png 500w, images/File-Upload-2-p-800.png 800w, images/File-Upload-2.png 1080w"
              alt=""
              className="image-7"
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default FileInput;
