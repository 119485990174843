import React from "react";
import Page from "../components/Page";
import QuoteQuestions from "../components/QuoteQuestions";
import LoadingPage from "./LoadingPage";
import Sections from "../Sections/Sections";

function IndexPage({ cms }) {
  if (!cms) return <LoadingPage />;

  return (
    <Page cms={cms}>
      {/* <Page data-wf-page={cms["data-wf-page"] || "64188977b1c0b452a2ca4a83"} data-wf-site={cms["data-wf-site"] || "64188977b1c0b4021bca4a7f"}> */}

      <Sections sections={cms.Sections} />
    </Page>
  );
}

export default IndexPage;
