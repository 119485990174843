import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { strapiAuthenticatedEndpoints, strapiPublicEndpoints } from "./endpoints";
import Navigation from "./navigation/Navigation";
import * as CmsActions from "./store/actions/cms";
import * as AuthActions from "./store/actions/auth";
import jwt from "jwt-decode";

import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  // log our data to the console so that we can read it
  const dispatch = useDispatch();
  const { user, jwt } = useSelector((state) => state.auth);

  useEffect(() => {
    AOS.init({
      offset: 200, // offset (in px) from the original trigger point
      delay: 200, // values from 0 to 3000, with step 50ms
      duration: 800,
    });
  }, []);

  // persist user and token from local storage
  useEffect(() => {
    try {
      if (!user || !jwt) {
        let localUser = JSON.parse(localStorage.getItem("user"));
        let localToken = JSON.parse(localStorage.getItem("jwt"));
        if (localUser && localToken) {
          if (tokenIsValid(localToken)) {
            dispatch(AuthActions.authenticate(localToken, localUser));
          } else {
            dispatch(AuthActions.logout());
          }
        }
      }
    } catch (error) {
      return;
    }
  }, []);

  // fetch all of our data on app load from Strapi.
  // To edit what endpoints are accessed, edit the ./endpoints.js file
  useEffect(() => {
    strapiPublicEndpoints.forEach((el) => dispatch(CmsActions.fetchCms(el.slug, el.populate)));
  }, []);

  useEffect(() => {
    if (jwt) strapiAuthenticatedEndpoints.forEach((el) => dispatch(CmsActions.fetchCms(el.slug, el.populate, jwt)));
  }, [jwt]);

  // Display our app navigator component from ./navigation/Navigation.js
  return <Navigation />;
}

export default App;

const tokenIsValid = (token) => {
  var current_time = new Date().getTime() / 1000;
  var decoded = jwt(token);
  return current_time < decoded.exp;
};
