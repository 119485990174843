import React from "react";
import CallToAction from "./CallToAction";
import GoogleReviews from "./GoogleReviews";
import Hero from "./Hero";
import Quotes from "./Quotes";
import ServiceCards from "./ServiceCards";
import Socials from "./Socials";
import Suppliers from "./Suppliers";
import FAQs from "./FAQs";
import Projects from "./Projects";
import QuoteForm from "./QuoteForm";
import Text from "./Text";
import Services from "./Services";
import SmallHero from "./SmallHero";
import Staff from "./Staff";
import Videos from "./Videos";
import VideoCards from "./VideoCards";
import Splide from "./Splide";
import { v4 as uuid } from "uuid";

const Sections = ({ sections }) => {
  const lookup = {
    "sections.call-to-action": CallToAction,
    "sections.google-reviews": GoogleReviews,
    "sections.hero": Hero,
    "sections.quotes": Quotes,
    "sections.service-cards": ServiceCards,
    "sections.socials": Socials,
    "sections.suppliers": Suppliers,
    "sections.fa-qs": FAQs,
    "sections.projects": Projects,
    "sections.quote-form": QuoteForm,
    "sections.rich-text": Text,
    "sections.services": Services,
    "sections.small-hero": SmallHero,
    "sections.staff": Staff,
    "sections.videos": Videos,
    "sections.video-cards": VideoCards,
    "sections.splide": Splide,
  };

  return (
    <>
      {sections.map((el) => {
        const Component = lookup[el.__component];
        return <Component key={uuid()} data={el} />;
      })}
    </>
  );
};

export default Sections;
