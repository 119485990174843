import React from "react";
import { useQuickbooksFunctions } from "../hooks/useQuickbooksFunctions";
import { v4 as uuid } from "uuid";
const InvoiceTab = ({ active }) => {
  const { invoices, fetchInvoicePDF, payInvoice, isPaying, isFetchingPDF } = useQuickbooksFunctions();

  return (
    <div data-w-tab="Tab 1" className={`tab-pane w-tab-pane ${active && "w--tab-active"}`}>
      <h2 className="subtleheading left">{invoices?.length ? "Invoices" : "No Invoices"}</h2>
      {invoices?.length > 0 && (
        <div className="w-layout-grid grid-5">
          {invoices.map((el) => {
            return <Invoice data={el} fetchInvoicePDF={fetchInvoicePDF} payInvoice={payInvoice} isPaying={isPaying} isFetchingPDF={isFetchingPDF} />;
          })}
        </div>
      )}
    </div>
  );
};

export default InvoiceTab;

const Invoice = ({ data, fetchInvoicePDF, payInvoice, isPaying, isFetchingPDF }) => {
  return (
    <div key={uuid()} className={`invoice ${data.Balance === 0 ? "paid" : "active"}`}>
      <div>#{data.DocNumber}</div>
      <div className="text-block-3">Payment Due {new Date(data.DueDate).toDateString()}</div>
      <div className="div-block-20">
        <div className="text-block-4">
          {data.CurrencyRef.value} ${data.Balance}
        </div>
        {data.Balance !== data.TotalAmt && <div className="text-block-5">/{data.TotalAmt}</div>}
      </div>
      <div className="invoice-actions">
        {data.Balance !== 0 && (
          <div onClick={() => payInvoice(data.Id)} className="button invoice-action w-button">
            {isPaying ? "..." : "Pay"}
          </div>
        )}
        <div className="button" onClick={() => fetchInvoicePDF(data.Id)}>
          {isFetchingPDF ? (
            "..."
          ) : (
            <div className="w-embed">
              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" className="">
                <path
                  fill="#fff"
                  fillRule="evenodd"
                  d="M20 11c.553 0 1 .448 1 1v6c0 1.654-1.346 3-3 3H6c-1.654 0-3-1.346-3-3v-6c0-.552.447-1 1-1 .553 0 1 .448 1 1v6c0 .55.448 1 1 1h12c.552 0 1-.45 1-1v-6c0-.552.447-1 1-1zm-8-8c.553 0 1 .448 1 1v9.586l2.293-2.293c.391-.391 1.023-.391 1.414 0 .391.39.391 1.023 0 1.414l-3.999 3.999c-.093.093-.203.166-.326.217-.122.051-.252.077-.382.077s-.26-.026-.382-.077c-.123-.051-.233-.124-.326-.217l-3.999-3.999c-.391-.391-.391-1.023 0-1.414s1.023-.391 1.414 0L11 13.586V4c0-.552.447-1 1-1z"
                />
              </svg>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
