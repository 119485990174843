import React from "react";
import Button from "../components/Button";
import { v4 as uuid } from "uuid";
import { Link } from "react-router-dom";

const ServiceCards = ({ data }) => {
  return (
    <div className="section wf-section">
      <div className="container homepage-services">
        <div className="serviceCards">
          {data?.service?.map((el) => (
            <Card key={uuid()} data={el?.service?.data?.attributes} />
          ))}
        </div>
        <h2 className="subtleheading">{data.text}</h2>
        <Button className="text-link" data={data.button} />
      </div>
    </div>
  );
};

export default ServiceCards;

const Card = ({ data }) => {
  if (!data) return;
  return (
    <Link data-aos="fade-up" id="w-node-_563148e0-9405-419c-a139-de2cf48bf901-a2ca4a83" to={`/services?q=${data.title}`} className="serviceicon_div w-inline-block">
      <div className="servicespics _2" style={{ backgroundImage: `url(${data.card_photo?.data.attributes.url})` }} />
      <div className="button service-btn">{data.title}</div>
    </Link>
  );
};
