import React, { useEffect } from "react";
import { v4 as uuid } from "uuid";

const Splide = ({ data }) => {
  console.log(data);
  return (
    <div className="imgRow">
      {data?.images?.data?.map((el) => {
        return <img key={uuid()} src={el?.attributes?.url} />;
      })}
    </div>
  );
};

export default Splide;
