import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as QuickbooksActions from "../store/actions/quickbooks";
import axios from "axios";
import FileSaver from "file-saver";

// handles Strapi auth flow: login
export const useQuickbooksFunctions = () => {
  const dispatch = useDispatch();
  const { invoices } = useSelector((state) => state.quickbooks);
  const { jwt } = useSelector((state) => state.auth);
  const [isPaying, setIsPaying] = useState(false);
  const [isFetchingPDF, setIsFetchingPDF] = useState(false);

  // get data on load
  useEffect(() => {
    if (jwt) fetchInvoices();
    return () => {
      QuickbooksActions.clearInvoices();
    };
  }, [jwt]);

  // fetch invoices from bellwoods integration
  const fetchInvoices = () => {
    dispatch(QuickbooksActions.fetchInvoices(jwt));
  };

  // fetch one invoice as a PDF from bellwoods integration
  const fetchInvoicePDF = async (invoiceId) => {
    setIsFetchingPDF(true);
    // get payment link
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/api/quickbooks/invoices/pdf/${invoiceId}`,
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/pdf",
      },
    };
    const response = await axios(config);
    console.log(response);

    // navigate to link
    if (response.data) {
      setIsFetchingPDF(false);
      downloadPDF(response.data, invoiceId);
      // window.open(response.data);
    } else {
      alert("Something went wrong!");
    }
  };

  const payInvoice = async (invoiceId) => {
    setIsPaying(true);
    // get payment link
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/api/quickbooks/invoices/link/${invoiceId}`,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };
    const response = await axios(config);

    // navigate to link
    if (response.data) {
      setIsPaying(false);
      window.open(response.data);
    } else {
      alert("Something went wrong!");
    }
  };

  // return those functions
  return {
    invoices,
    fetchInvoices,
    fetchInvoicePDF,
    payInvoice,
    isPaying,
    isFetchingPDF,
  };
};

function downloadPDF(pdf, id) {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement("a");
  const fileName = `invoice_${id}.pdf`;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}
