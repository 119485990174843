import React from "react";

const GoogleReviews = ({ data }) => {
  return (
    <div className="wf-section">
      <div className="w-container">
        <div data-aos="fade-up" className="html-embed w-embed w-script">
          <div className={data.elfsight_class} />
          <div className="cover" />
        </div>
      </div>
    </div>
  );
};

export default GoogleReviews;
