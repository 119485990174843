import React from "react";
import { v4 as uuid } from "uuid";

const Socials = ({ data }) => {
  return (
    <div className="section projects">
      <div className="container">
        <div className="social-links">
          <div className="div-block-23">
            <h2 data-aos="fade-up" className="subtleheading">
              {data.text}
            </h2>
            <div data-aos="fade-up" className="text-link hover-none">
              {data.subtext}
            </div>
          </div>
          <div className="div-block-24">
            {data?.socials?.data?.map((el) => {
              return (
                <a data-aos="fade-up" key={uuid()} href={el?.attributes?.url} target="_blank" className="social-link w-inline-block">
                  <img src={el?.attributes?.icon?.data?.attributes?.url} loading="lazy" alt={el?.attributes?.name} className="socail-icon" />
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Socials;
