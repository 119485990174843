import React from "react";
import { v4 as uuid } from "uuid";

const VideoCards = () => {
  return (
    <div className="section">
      <div className="container">
        <h2 className="subtleheading space">Learn a bit more</h2>
        <div className="w-layout-grid video-grid">
          <div id="w-node-_02508a00-0296-5f81-2236-b92ce8a305fe-15ca4a92" className="video-card">
            <div style={{ paddingTop: "56.17021276595745%" }} className="w-video w-embed">
              <iframe
                className="embedly-embed"
                src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FI6B-SkuCDDs%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DI6B-SkuCDDs&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FI6B-SkuCDDs%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube"
                scrolling="no"
                allowFullScreen=""
                title="2021 HARDSCAPE SET UP- (Two Person Crew)"
              />
            </div>
            <p className="paragraph-2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo
              diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.
            </p>
          </div>
          <div id="w-node-_1af7fedb-a618-9f74-2c59-aec9fd8e76e5-15ca4a92" className="video-card">
            <div style={{ paddingTop: "56.17021276595745%" }} className="w-video w-embed">
              <iframe
                className="embedly-embed"
                src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FK3lGnB7loYY%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DK3lGnB7loYY&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FK3lGnB7loYY%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube"
                scrolling="no"
                allowFullScreen=""
                title="TIMELAPSE- New backyard patio"
              />
            </div>
            <p className="paragraph-2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo
              diam libero.
            </p>
          </div>
          <div id="w-node-_82a246b7-5ac2-e276-2cd1-f6261237e89b-15ca4a92" className="video-card">
            <div style={{ paddingTop: "56.17021276595745%" }} className="w-video w-embed">
              <iframe
                className="embedly-embed"
                src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FaOCZcNbhWd0%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DaOCZcNbhWd0&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FaOCZcNbhWd0%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube"
                scrolling="no"
                allowFullScreen=""
                title="Front Entrance Renovation! Hardscaping"
              />
            </div>
            <p className="paragraph-2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius
              enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem
              imperdiet. Nunc ut sem vitae risus tristique posuere.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCards;
