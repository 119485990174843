import React, { useState } from "react";
import Page from "../components/Page";
import { useAuthFunctions } from "../hooks/useAuthFunctions";

import { useSelector } from "react-redux";
import SmallHero from "../Sections/SmallHero";
import InvoiceTab from "../components/InvoiceTab";
import ProjectsTab from "../components/ProjectsTab";
import ProposalTab from "../components/ProposalTab";

const ProfilePage = () => {
  const { user } = useAuthFunctions();

  const { data } = useSelector((state) => state.cms);
  const cms = data["profile-page"].attributes;
  const [tab, setTab] = useState(0);
  return (
    <Page data-wf-page={"64188977b1c0b452a2ca4a83"} data-wf-site={"64188977b1c0b4021bca4a7f"}>
      <SmallHero data={cms.hero} name={user?.name} />
      <div className="section projects">
        <div className="container">
          <div data-current="Tab 3" data-easing="ease" data-duration-in={300} data-duration-out={100} className="w-tabs">
            <div className="tabs-menu w-tab-menu">
              <a data-w-tab="Tab 3" className={`tab-link w-inline-block w-tab-link  ${tab === 0 && "w--current"}`} onClick={() => setTab(0)}>
                <div>Proposals</div>
              </a>

              <a data-w-tab="Tab 2" className={`tab-link w-inline-block w-tab-link  ${tab === 1 && "w--current"}`} onClick={() => setTab(1)}>
                <div>Projects</div>
              </a>

              <a data-w-tab="Tab 1" className={`tab-link w-inline-block w-tab-link  ${tab === 2 && "w--current"}`} onClick={() => setTab(2)}>
                <div>Invoices</div>
              </a>
            </div>
            <div className="w-tab-content">
              <ProposalTab active={tab === 0} />
              <ProjectsTab active={tab === 1} />
              <InvoiceTab active={tab === 2} />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default ProfilePage;
