import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
const ProjectPhotoSlider = ({ data }) => {
  const [index, setIndex] = useState(0);

  const stepIndex = (step) => {
    let tmp = index + step;
    if (tmp >= data.length) tmp = 0;
    if (tmp < 0) tmp = data.length - 1;
    setIndex(tmp);
  };

  useEffect(() => {
    setIndex(0);
  }, [data]);

  if (!data || data.length === 0)
    return (
      <div style={{ textAlign: "center" }}>
        <h2>Sorry, no photos found!</h2>
        <p>Try some different filters.</p>
      </div>
    );
  return (
    <div className="project-body">
      <div className="lightbox-main">
        <img src={data?.[index]} loading="lazy" alt="" className="image-4" />
        {data.length > 1 && (
          <>
            <div className="lightbox-controls left">
              <img onClick={() => stepIndex(-1)} src="images/Untitled-design.svg" loading="lazy" alt="" className="lightbox-arrow left" />
            </div>
            <div className="lightbox-controls right">
              <img onClick={() => stepIndex(1)} src="images/Untitled-design.svg" loading="lazy" alt="" className="lightbox-arrow" />
            </div>
          </>
        )}
      </div>
      {data.length > 1 && (
        <div className="div-block-18">
          <div className="gradient-cover hidden">
            <div className="w-embed" />
          </div>
          <div className="lightbox-thumbnails">
            {data.map((el, i) => (
              <img key={uuid()} onClick={() => setIndex(i)} src={el} loading="lazy" sizes="150px" srcSet={el} alt="" className={`lightbox-thumbnail ${index === i && "current"}`} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectPhotoSlider;
