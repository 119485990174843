import React from "react";
import Waypoint from "../components/Waypoint";

const SmallHero = ({ data, name, text }) => {
  return (
    <>
      <div
        data-w-id="e48f0799-1b01-88c2-af77-9616e39359c6"
        className="subpagehero shadow wf-section"
        style={{
          backgroundImage: `radial-gradient(circle farthest-corner at 50% 50%, rgba(0, 0, 0, 0.72), rgba(0, 0, 0, 0.08)), url(${data?.background?.data?.attributes?.url})`,
        }}
      >
        <div className="div-block-15">
          <img src={data?.icon?.data?.attributes?.url} loading="lazy" data-w-id="2b145943-9a81-ec94-ff2c-3b4bad83836b" alt="" className="image-2" />
          <h1 data-w-id="e48f0799-1b01-88c2-af77-9616e39359c8" className="heading-jumbo green text-shadow">
            {text || data.text.replace("{{name}}", name)}
          </h1>
          <div data-w-id="e48f0799-1b01-88c2-af77-9616e39359ca" className="subheading text-shadow smallhero">
            {data.subtext}
          </div>
        </div>
      </div>
      <Waypoint />
    </>
  );
};

export default SmallHero;
