import { FETCH_INVOICES, CLEAR_INVOICES } from "../actions/quickbooks";

const initialState = {
  invoices: [],
  pdf: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INVOICES:
      return {
        ...state,
        invoices: action.data,
      };

    case CLEAR_INVOICES:
      return {
        ...state,
        invoices: initialState.invoices,
      };

    default:
      return state;
  }
};
