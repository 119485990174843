import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import CrossfadeImage from "react-crossfade-image";
const ImageFader = (props) => {
  let timer;
  const [index, setIndex] = useState(0);

  const updateIndex = () => {
    timer =
      !timer &&
      setInterval(() => {
        setIndex((prev) => {
          let tmp = prev + 1;
          if (props?.images?.length && tmp > props.images.length - 1) tmp = 0;
          return tmp;
        }); // new
      }, 2000);
  };

  useEffect(() => {
    updateIndex();
    return () => clearInterval(timer);
  }, []);

  if (!props.images) return;
  if (!props.images[index]?.image?.data?.attributes?.url) return;
  return (
    <CrossfadeImage
      style={{
        width: "100%",
        height: "100%",
        objectFit: "cover",
      }}
      containerClass="image"
      src={props.images[index]?.image?.data?.attributes?.url}
    />
  );
};

export default ImageFader;
