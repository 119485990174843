import React from "react";
import Button from "../components/Button";

const CallToAction = ({ data }) => {
  return (
    <div className="section cc-cta shadow" style={{ backgroundImage: `linear-gradient(167deg, rgba(17, 17, 17, 0.59) 50%, hsla(0, 0%, 100%, 0)), url(${data?.background?.data?.attributes?.url})` }}>
      <div className="container cta">
        <div className="cta-wrap">
          <div>
            <div className="cta-text">
              <div data-aos="fade-up" className="heading-jumbo-small text-shadow">
                {data.text}
                <br />
              </div>
              <div data-aos="fade-up" className="paragraph-bigger cc-bigger-light text-shadow">
                {data.subtext}
                <br />
              </div>
            </div>
            <Button data={data.button} className="button cc-jumbo-button shadow w-inline-block" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
