// This fixes a react navigation bug.
// When navigating between pages, this ensures that we scroll to the top of the page
// doesnt scroll if only query params change
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import usePrevious from "../hooks/usePrevious";

function ScrollToTop(props) {
  let location = useLocation();

  const prevLocation = usePrevious(location);

  useEffect(() => {
    if (location?.pathname !== prevLocation?.pathname) window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>;
}

export default ScrollToTop;
