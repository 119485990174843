import React, { useState } from "react";
import { v4 as uuid } from "uuid";
const Videos = ({ data }) => {
  return (
    <div className="section projects">
      <div className="container">
        <div className="w-layout-grid grid-7">
          {data?.cards?.map((el) => (
            <Card data={el} key={uuid()} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Videos;

const Card = ({ data }) => {
  const [showCover, setShowCover] = useState(!!data?.cover?.data?.attributes?.url);

  console.log(data);
  return (
    <div data-aos="fade-up" className="video-container">
      {showCover ? (
        <img onClick={() => setShowCover(false)} className="embedly-embed" src={data?.cover?.data?.attributes?.url} style={{ width: "100%", height: "100%" }} />
      ) : (
        <div className="video w-video w-embed">
          {data?.video?.data?.attributes?.url ? (
            <video width="100%" height="100%" controls>
              <source src={data.video.data.attributes.url} type={data.video.data.attributes.mime} />
            </video>
          ) : (
            <iframe
              className="embedly-embed"
              src={data.videoUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          )}
        </div>
      )}
      <h2 className="heading-2">{data.text}</h2>
      <p>{data.subText}</p>
    </div>
  );
};
