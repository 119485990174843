import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ButtonRow from "../components/ButtonRow";
import ProjectPhotoSlider from "../components/ProjectPhotoSlider";

const Projects = () => {
  const { data } = useSelector((state) => state.cms);
  const { services, projects } = data;
  const [projectIndex, setProjectIndex] = useState(-1);
  const [serviceIndex, setServiceIndex] = useState(-1);

  const [availablePhotos, setAvailablePhotos] = useState([]);
  const [allPhotoData, setAllPhotoData] = useState([]);
  const [filteredServices, setFilteredServices] = useState(services);

  // get all the photo data sorted by service and project
  useEffect(() => {
    if (services && projects) setAllPhotoData(getAllPhotoData(projects, services));
  }, [services, projects]);

  // when we change filters, set the avialable photos for our slider
  useEffect(() => {
    if (allPhotoData) {
      let projectId;
      let serviceId;

      if (projectIndex > -1) projectId = projects[projectIndex].id;
      if (serviceIndex > -1) serviceId = filteredServices[serviceIndex].id;
      setAvailablePhotos(getAvailablePhotos(allPhotoData, projectId, serviceId));
    }
  }, [projectIndex, serviceIndex, allPhotoData]);

  //  set avaialble services based on selected projects
  useEffect(() => {
    let serviceIds;
    if (projectIndex !== -1) {
      serviceIds = getServicesInProject(projects[projectIndex]);
    } else {
      serviceIds = getAllServices(services);
    }
    // check that selected service is still avaialble
    if (serviceIndex !== -1 && !serviceIds.includes(filteredServices[serviceIndex]?.id)) {
      setServiceIndex(-1);
    }
    setFilteredServices(services.filter((el) => serviceIds.includes(el.id)));
  }, [projectIndex, serviceIndex]);

  return (
    <div className="section projects" style={{ marginTop: 0 }}>
      <div className="container">
        <div className="projects-heading">
          <div className="text-block-2">Filter by Project</div>
          <div className="div-block-28">
            <ButtonRow
              active={projectIndex + 1}
              data={[{ attributes: { title: "All" } }, ...projects]}
              handleChange={(i) => setProjectIndex(i - 1)}
              containerClass="project-filters"
              linkClass="button project-filter w-button"
              linkActiveClass="purple"
              textAttribute="title"
            />
            <div className="gradient-cover right" />
          </div>
          <div className="text-block-2">And By Service</div>
          <div className="div-block-28">
            <ButtonRow
              active={serviceIndex + 1}
              data={[{ attributes: { title: "All" } }, ...filteredServices]}
              handleChange={(i) => setServiceIndex(i - 1)}
              containerClass="project-filters"
              linkClass="button project-filter w-button"
              linkActiveClass="purple"
              textAttribute="title"
            />

            <div className="gradient-cover right" />
          </div>
        </div>
        <ProjectPhotoSlider data={availablePhotos} />
      </div>
    </div>
  );
};

export default Projects;

//
//
// HELPERS
//
//

const getServicesInProject = (project) => {
  let tmp = [];
  project?.attributes?.photos?.map((el) => {
    el.services.data.map((el2) => {
      tmp.push(el2.id);
    });
  });
  return tmp;
};
const getAllServices = (services) => {
  return services.map((el) => el.id);
};
const getPhotosInProject = (project) => {
  let photos = project?.attributes?.photos?.map((photo) => {
    let serviceIds = [];
    photo?.services?.data?.map((service) => {
      serviceIds.push(service.id);
      return service;
    });

    return {
      url: photo?.image?.data?.attributes?.url,
      services: serviceIds,
      projects: [project.id],
    };
  });
  return photos;
};
const getPhotosInService = (service) => {
  let photos = [];
  if (service?.attributes?.slider_photos?.data) {
    photos = service?.attributes?.slider_photos?.data?.map((el) => {
      return {
        url: el?.attributes?.url,
        services: [service.id],
        projects: [],
      };
    });
  }
  return photos;
};

// clean up our data
const getAllPhotoData = (projects, services) => {
  let projectCollector = projects.map((el) => getPhotosInProject(el)).flat();
  let serviceCollector = services.map((el) => getPhotosInService(el)).flat();
  return [...projectCollector, ...serviceCollector];
};

// filter out photos avaialble for specific project/service combo
const getAvailablePhotos = (photos, projectId, serviceId) => {
  let tmp;
  if (projectId && serviceId) {
    tmp = photos.filter((el) => el.projects.includes(projectId) && el.services.includes(serviceId));
  } else if (projectId && !serviceId) {
    tmp = photos.filter((el) => el.projects.includes(projectId));
  } else if (!projectId && serviceId) {
    tmp = photos.filter((el) => el.services.includes(serviceId));
  } else {
    // !projectId && !serviceId
    tmp = photos;
  }
  let unique = [...new Set(tmp.map((el) => el.url))];
  return unique;
};
