import React from "react";
import { Link } from "react-router-dom";

function ErrorPage() {
  return (
    <div className="utility-page-wrap">
      <div className="_404-wrap">
        <div className="_404-content-wrap">
          <img src="images/Logo-icon.png" loading="lazy" alt="" className="image-9" />
          <div className="heading-jumbo">Page Not Found</div>
          <div className="paragraph-bigger cc-bigger-light">The page you are looking for doesn't exist or has been moved.</div>
        </div>
        <Link to="/" className="button cc-white-button purple shadow w-inline-block">
          <div>Go Back to Safety</div>
        </Link>
      </div>
    </div>
  );
}

export default ErrorPage;
