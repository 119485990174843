// This file defines the strapi endpoints you want to pull dtata from (collections & single types)
// This data will be fetched every time the page is loaded or refreshed

// slug = database table name, also known as API ID (Plural)
// fields = what columns from the table do you want to use?
// populate = how to show nested components & relations (doc: https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/populating-fields.html#relation-media-fields)
// There's two formats for doing this. Populate can be a string or qs.stringify()

import qs from "qs";

export const strapiPublicEndpoints = [
  // Single Types
  {
    slug: "navbar",
    populate: qs.stringify({
      populate: {
        links: {
          populate: {
            subLinks: {
              populate: "*",
            },
          },
        },
        logo: {
          populate: "*",
        },
        logo_small: {
          populate: "*",
        },
        button: {
          populate: "*",
        },
      },
    }),
  },

  {
    slug: "profile-page",
    populate: "populate=deep,3",
  },

  {
    slug: "auth-page",
    populate: "populate=deep,3",
  },

  // Collections
  {
    slug: "pages",
    populate: "populate=deep,6",
  },
  {
    slug: "projects",
    populate: qs.stringify({
      sort: ["title"],
      populate: {
        photos: {
          populate: {
            image: {
              populate: "*",
            },
            services: {
              populate: "*",
            },
          },
        },
      },
    }),
  },
  {
    slug: "quote-questions",
    populate: "populate=deep,4",
  },
  {
    slug: "services",
    populate: qs.stringify({
      sort: ["title"],
      populate: {
        card_photo: {
          populate: "*",
        },
        slider_photos: {
          populate: "*",
        },
      },
    }),
  },
  {
    slug: "socials",
    populate: "populate=*",
  },
  {
    slug: "staffs",
    populate: "populate=*",
  },
  {
    slug: "suppliers",
    populate: "populate=*",
  },
];

export const strapiAuthenticatedEndpoints = [
  {
    slug: "proposals",
    populate: "populate=*",
  },
];

// An example for getting complicated with the populate feild:
//   populate: qs.stringify({
//     populate: {
//       exampleSlider: {
//         populate: {
//           items: {
//             populate: "background",
//           },
//         },
//       },
//       exampleAccordion: {
//         populate: ["items"],
//       },
//     },
//   }),
