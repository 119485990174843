import React, { useRef, useState } from "react";
import * as NavActions from "../store/actions/nav";
import { useDispatch } from "react-redux";

const Waypoint = () => {
  const inputRef = useRef();
  const dispatch = useDispatch();

  const changeNavbarColor = () => {
    if (inputRef.current) {
      var bodyRect = document.body.getBoundingClientRect();
      var elemRect = inputRef.current.getBoundingClientRect();
      var offset = elemRect.top - bodyRect.top;
      if (window.scrollY >= offset) {
        dispatch(NavActions.setColorChange(true));
      } else {
        dispatch(NavActions.setColorChange(false));
      }
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  return (
    <>
      <div className="waypoint" ref={inputRef} />
    </>
  );
};

export default Waypoint;
