import React, { useEffect } from "react";
import WebFont from "webfontloader";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

function Head({ cms }) {
  const { id } = useParams();

  useEffect(() => {
    // If your head code includes any google fonts, place them here
    WebFont.load({
      google: {
        families: ["Alata:regular", "Quicksand:regular", "Arapey:regular,italic"],
      },
    });
  }, []);

  //   Depending on the page we're on, we'll want different head tags. make a 'case' for each page slug,
  //   Convert your webflow <head></head> code to JSX and paste it between these <Helmet></Helmet> tags

  if (cms)
    return (
      <Helmet>
        <meta charset="utf-8" />
        <title>{cms.title} | Mark of Excellence</title>
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta name="description" content={cms.description} />
      </Helmet>
    );

  switch (window.location.pathname) {
    case "/profile":
      return (
        <Helmet>
          <meta charset="utf-8" />
          <title>User Profile | Mark of Excellence</title>
          <meta content="width=device-width, initial-scale=1" name="viewport" />
        </Helmet>
      );

    case "/auth":
      return (
        <Helmet>
          <meta charset="utf-8" />
          <title>Authenticate | Mark of Excellence</title>
          <meta content="width=device-width, initial-scale=1" name="viewport" />
        </Helmet>
      );

    default:
      return (
        <Helmet>
          <meta charset="utf-8" />
          <title>Error | Mark of Excellence</title>
          <meta content="width=device-width, initial-scale=1" name="viewport" />
        </Helmet>
      );
  }
}

export default Head;
