export const SET_COLOR_CHANGE = "SET_COLOR_CHANGE";

export const setColorChange = (colorChange) => {
  return async (dispatch) => {
    dispatch({
      type: SET_COLOR_CHANGE,
      colorChange: colorChange,
    });
  };
};
