import React from "react";
import { useAuthFunctions } from "../hooks/useAuthFunctions";
import { v4 as uuid } from "uuid";

const ProposalTab = ({ active }) => {
  const { user } = useAuthFunctions();

  if (!user?.proposals || user.proposals.length === 0)
    return (
      <div data-w-tab="Tab 3" className="tab-pane w-tab-pane">
        <h2 className="subtleheading left">No Proposals</h2>
      </div>
    );

  return (
    <div data-w-tab="Tab 3" className={`tab-pane w-tab-pane ${active && "w--tab-active"}`}>
      {user.proposals?.map((el) => {
        return (
          <a key={uuid()} target="_blank" className="proposal" href={el.portal_url}>
            <div className="text-block-10">{el.title}</div>
            <img
              src="images/new-tab.png"
              loading="lazy"
              sizes="(max-width: 1279px) 30.000001907348633px, (max-width: 1919px) 2vw, 30.000001907348633px"
              srcSet="images/new-tab-p-500.png 500w, images/new-tab.png 540w"
              alt=""
              className="proposal-icon"
            />
          </a>
        );
      })}
    </div>
  );
};

export default ProposalTab;
