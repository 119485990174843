import React from "react";
import Page from "../components/Page";
import { useAuthFunctions } from "../hooks/useAuthFunctions";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import SmallHero from "../Sections/SmallHero";
import { useSelector } from "react-redux";

const AuthPage = (props) => {
  const { data } = useSelector((state) => state.cms);
  const cms = data["auth-page"].attributes;
  let { search } = useLocation();

  const query = new URLSearchParams(search);
  const state = query.get("q") || "login";

  const renderForm = () => {
    switch (state) {
      case "signup":
        return <SignupForm />;
      case "login":
        return <LoginForm />;
      case "forgot password":
        return <ForgotPasswordForm />;
    }
  };

  return (
    <Page>
      <SmallHero data={cms.hero} text={state?.toUpperCase()} />

      {renderForm()}
    </Page>
  );
};

export default AuthPage;

const SignupForm = () => {
  const { handleSignup } = useAuthFunctions();

  return (
    <div data-aos="fade-up" className="section contact-section">
      <div className="container">
        <div className="auth-form-wrap">
          <div className="contact-form w-form">
            <form className="get-in-touch-form" onSubmit={handleSignup}>
              <label htmlFor="name">Name</label>
              <input type="text" className="text-input w-input" maxLength={256} name="name" placeholder="Enter your name" />
              <label htmlFor="phone">Phone</label>
              <input type="tel" className="text-input w-input" maxLength={256} name="phone" placeholder="Enter your phone" />
              <label htmlFor="email">Email</label>
              <input type="email" className="text-input w-input" maxLength={256} name="email" placeholder="Enter your email" required />
              <label htmlFor="password">Password</label>
              <input type="password" className="text-input w-input" maxLength={256} name="password" placeholder="Enter your password" required />
              <input type="submit" defaultValue="Signup" className="button purple w-button" />
              <div className="div-block-17">
                <Link to="/auth?q=login" className="link dark">
                  Already have an account?
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export const LoginForm = () => {
  const { handleLogin } = useAuthFunctions();
  return (
    <div data-aos="fade-up" className="section contact-section">
      <div className="container">
        <div className="auth-form-wrap">
          <div className="contact-form w-form">
            <form className="get-in-touch-form" onSubmit={handleLogin}>
              <label htmlFor="email">Email</label>
              <input type="email" className="text-input w-input" maxLength={256} name="email" placeholder="Enter your email" required />
              <label htmlFor="password">Password</label>
              <input type="password" className="text-input w-input" maxLength={256} name="password" placeholder="Enter your password" required />
              <input type="submit" className="button purple w-button" />
              <div className="div-block-17">
                <Link to="/auth?q=signup" className="link dark">
                  Need an account?
                </Link>

                <Link to="/auth?q=forgot password" className="link dark">
                  Forgot Password?
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ForgotPasswordForm = () => {
  const { handleForgotPassword } = useAuthFunctions();
  return (
    <div data-aos="fade-up" className="section contact-section">
      <div className="container">
        <div className="auth-form-wrap">
          <div className="contact-form w-form">
            <form onSubmit={handleForgotPassword} className="get-in-touch-form">
              <label htmlFor="email">Email</label>
              <input type="email" className="text-input w-input" maxLength={256} name="email" placeholder="Enter your email" required />
              <input type="submit" className="button purple w-button" />
              <div className="div-block-17">
                <Link to="/auth?q=login" className="link dark">
                  Login
                </Link>
                <Link to="/auth?q=signup" className="link dark">
                  Signup
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
