import React from "react";
import RichText from "../components/RichText";

const Text = ({ data }) => {
  return (
    <div className="section" style={{ marginTop: 50 }}>
      <div className="container">
        <div data-aos="fade-up" className="projects-heading">
          <RichText text={data.text} />
        </div>
      </div>
    </div>
  );
};

export default Text;
