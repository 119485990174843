import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

const Suppliers = ({ data }) => {
  const { data: cmsData } = useSelector((state) => state.cms);
  const { suppliers } = cmsData;

  useEffect(() => {
    window.mountSplide();
    return () => {
      window.unmountSplide();
    };
  }, []);

  return (
    <div className="section suppliers wf-section">
      <div className="w-container">
        <h2 data-aos="fade-up" className="subtleheading small">
          {data.text}
        </h2>
        <p data-aos="fade-up" className="paragraph">
          {data.subtext}
        </p>
        <div className="div-block-11">
          <div className="splide splide--team">
            <div className="splide__track">
              <div className="splide__list">
                {suppliers?.map((el) => {
                  return (
                    <div key={uuid()} className="splide__slide">
                      <img src={el.attributes?.logo?.data?.attributes?.url} loading="lazy" alt="" />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="splide__arrows">
              <div className="splide__arrow-embed w-embed">
                <button className="splide__arrow splide__arrow--prev" />
              </div>
              <div className="splide__arrow-embed w-embed">
                <button className="splide__arrow splide__arrow--next" />
              </div>
            </div>
            <div className="global_display--none w-embed"></div>
          </div>
          <div className="gradient-cover" />
        </div>
      </div>
    </div>
  );
};

export default Suppliers;
