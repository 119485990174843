// DOCUMENTATION:
// https://reactrouter.com/docs/en/v6

// This file sets up the page navigation or the sitemap of our website.
// This is not our Navbar component

import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

// Import the necessary page files from ../pages/Something.js
import IndexPage from "../pages/IndexPage";
import ErrorPage from "../pages/ErrorPage";
import AuthPage from "../pages/AuthPage";
import ProfilePage from "../pages/ProfilePage";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import LoadingPage from "../pages/LoadingPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";

function Navigation(props) {
  const { data } = useSelector((state) => state.cms);

  if (!data || !data.pages) return <LoadingPage />;
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          {/* We need to add a route for each of our pages. 
          Path is the page slug www.something.com/slug. 
          Element is the React component to display when we get to that slug. */}
          {data?.pages?.map((el) => {
            return <Route key={uuid()} path={el?.attributes?.slug || "/"} element={<IndexPage cms={el?.attributes} />} />;
          })}
          <Route path="/reset-password" element={<ResetPasswordPage />} />

          <Route path="/auth" element={<AuthPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default Navigation;
